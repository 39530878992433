import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2406.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2406.000000) scale(0.100000,-0.100000)">
					<path d="M12435 22144 c-611 -37 -955 -82 -1450 -190 -421 -92 -907 -241
-1269 -389 -217 -89 -259 -107 -381 -163 -485 -223 -1039 -545 -1411 -820 -38
-28 -89 -66 -114 -84 -150 -110 -447 -353 -600 -493 -539 -492 -973 -997
-1367 -1590 -55 -82 -124 -190 -153 -240 -29 -49 -63 -106 -75 -125 -84 -133
-265 -474 -361 -680 -416 -892 -663 -1804 -755 -2775 -6 -66 -12 -121 -13
-122 -1 -2 -40 12 -87 31 -46 18 -131 51 -189 74 -323 125 -549 217 -660 267
-69 31 -190 86 -270 122 -80 35 -174 78 -210 94 -256 117 -346 156 -349 153
-3 -2 31 -41 75 -87 43 -45 113 -118 154 -162 110 -119 303 -326 315 -339 16
-19 121 -131 270 -291 77 -82 165 -177 195 -210 30 -33 69 -73 87 -89 21 -20
239 -121 680 -313 584 -256 663 -287 798 -323 83 -21 254 -65 380 -98 l230
-60 546 -41 547 -41 143 20 c79 11 356 47 614 81 460 59 475 62 715 126 135
36 301 80 370 98 469 123 566 154 730 228 91 40 217 97 280 125 63 27 171 76
240 107 69 32 159 72 200 90 182 78 717 319 735 330 11 7 72 39 135 72 63 33
194 102 290 153 96 51 222 117 280 147 58 31 141 75 185 98 44 24 163 87 265
140 102 54 212 112 245 130 33 18 114 61 180 95 66 35 183 96 260 137 123 64
697 338 1165 556 30 14 122 57 203 96 82 39 153 71 158 71 4 0 172 40 371 89
559 138 541 134 898 202 113 21 124 22 759 15 l644 -7 346 -44 c190 -25 441
-57 556 -71 238 -29 120 6 1125 -334 278 -93 570 -188 650 -211 80 -22 183
-51 230 -64 140 -40 739 -205 820 -226 41 -11 97 -27 124 -35 27 -7 52 -12 54
-9 3 3 -10 76 -28 162 -318 1540 -1084 2979 -2180 4098 -283 289 -454 446
-755 691 -33 26 -143 111 -245 187 -1248 936 -2732 1493 -4320 1623 -182 15
-859 27 -1005 18z m1005 -528 c524 -49 977 -129 1436 -256 324 -90 766 -246
1019 -360 22 -10 87 -39 145 -65 616 -276 1293 -708 1832 -1172 980 -842 1725
-1891 2199 -3093 58 -146 153 -420 148 -425 -2 -2 -206 71 -454 162 -467 171
-774 278 -1295 451 -281 93 -452 140 -985 267 -79 19 -150 26 -345 35 -135 6
-474 22 -755 35 -472 22 -523 22 -680 9 -93 -8 -355 -30 -581 -49 l-411 -35
-609 -201 c-627 -207 -603 -198 -1029 -399 -60 -29 -219 -103 -352 -166 -133
-62 -286 -134 -340 -160 -54 -25 -206 -97 -338 -159 -212 -99 -382 -180 -725
-342 -161 -77 -198 -92 -204 -86 -10 10 -6 2116 4 2295 11 188 36 321 75 398
55 108 176 209 301 250 138 46 200 52 619 59 l400 6 3 383 2 382 -2180 0
-2180 0 0 -384 0 -384 408 -5 c460 -5 545 -14 667 -71 112 -53 207 -165 248
-292 64 -194 66 -269 67 -1919 l0 -1461 -237 -109 c-131 -60 -287 -132 -348
-161 -60 -28 -193 -90 -295 -136 l-185 -85 -300 -67 c-407 -92 -488 -110 -645
-148 l-135 -33 -620 1 c-395 0 -658 5 -725 12 -58 7 -175 19 -260 27 -430 41
-728 77 -769 92 l-44 17 7 110 c32 539 170 1269 336 1783 335 1031 791 1863
1472 2683 183 220 527 576 738 766 30 27 57 51 60 54 3 3 39 34 80 70 41 36
77 67 80 70 3 3 39 32 80 65 41 33 77 63 80 66 18 20 363 272 500 364 358 242
805 492 1140 640 47 20 119 52 160 70 764 337 1728 563 2620 615 226 13 897 4
1100 -14z"/>
<path d="M17830 14030 c-41 -4 -273 -24 -515 -45 l-440 -36 -616 -204 c-556
-184 -636 -213 -810 -296 -273 -129 -396 -187 -604 -284 -38 -18 -119 -56
-180 -85 -60 -29 -175 -83 -255 -120 -80 -38 -317 -149 -525 -248 -209 -98
-445 -210 -525 -247 -80 -37 -273 -129 -430 -203 -157 -74 -303 -142 -325
-152 -22 -10 -148 -68 -280 -130 -132 -62 -265 -123 -295 -137 -30 -14 -116
-53 -190 -88 -224 -105 -700 -325 -955 -443 -237 -109 -243 -111 -445 -157
-113 -25 -273 -61 -356 -80 -83 -19 -193 -44 -245 -55 -52 -11 -150 -33 -219
-49 l-125 -29 -640 8 c-591 7 -657 10 -860 33 -121 14 -346 38 -500 53 l-280
26 -185 73 c-102 40 -257 100 -345 135 -88 34 -209 81 -270 105 -60 24 -213
84 -340 133 -126 49 -275 110 -330 135 -270 122 -523 235 -552 247 -18 7 -33
16 -33 20 0 4 -37 24 -82 45 -46 21 -128 59 -183 85 -118 55 -194 85 -200 78
-2 -2 0 -20 5 -39 6 -18 35 -135 66 -259 147 -597 346 -1135 624 -1691 463
-925 1092 -1750 1870 -2454 176 -158 488 -413 620 -505 19 -13 40 -28 45 -32
87 -69 355 -250 535 -360 1172 -719 2488 -1131 3862 -1209 234 -13 937 -7
1113 10 660 64 1124 149 1674 307 261 75 642 209 851 299 240 103 320 140 505
232 422 211 762 414 1100 657 61 44 133 95 160 115 28 19 129 98 225 175 944
754 1702 1691 2244 2775 112 224 127 256 233 506 273 647 478 1415 568 2129 9
71 18 133 21 137 2 4 170 -49 372 -118 214 -73 436 -141 532 -164 91 -22 230
-55 310 -74 80 -19 201 -48 270 -64 68 -17 133 -31 143 -31 22 0 33 -12 -118
124 -155 140 -178 160 -285 256 -52 47 -97 87 -100 90 -3 3 -43 39 -90 81 -67
59 -127 97 -285 183 -110 60 -234 128 -275 151 -142 80 -235 123 -345 158 -60
20 -270 90 -466 156 -263 89 -427 138 -625 186 -148 36 -321 79 -385 95 -111
29 -139 31 -525 50 -225 11 -539 27 -699 35 -304 16 -490 17 -615 5z m1235
-740 c196 -6 307 -17 745 -74 498 -65 520 -69 658 -113 79 -25 146 -51 148
-58 13 -32 -77 -624 -132 -875 -114 -518 -274 -1022 -459 -1440 -25 -58 -54
-123 -63 -145 -35 -87 -246 -499 -329 -645 -134 -233 -313 -514 -431 -675 -24
-33 -51 -71 -60 -84 -168 -237 -502 -628 -758 -886 -365 -367 -719 -665 -1134
-951 -174 -120 -426 -277 -645 -401 -87 -50 -529 -273 -540 -273 -3 0 -33 -13
-67 -29 -705 -327 -1629 -571 -2448 -646 -587 -53 -1180 -45 -1755 26 -734 90
-1565 322 -2203 615 -41 19 -76 34 -78 34 -3 0 -107 51 -232 113 -211 106
-548 292 -617 341 -16 12 -73 48 -125 81 -429 268 -917 661 -1305 1050 -153
153 -209 213 -390 416 -187 209 -441 547 -639 848 -113 172 -146 227 -283 461
-63 109 -262 503 -309 612 -151 352 -247 598 -232 598 10 0 133 -52 873 -369
475 -204 574 -247 654 -283 24 -10 65 -28 91 -38 26 -10 67 -28 91 -38 162
-74 224 -95 384 -137 99 -26 263 -69 365 -97 176 -47 197 -50 430 -69 135 -10
360 -28 501 -39 336 -27 391 -27 604 4 249 36 235 38 235 -26 0 -29 -5 -131
-10 -226 -21 -376 -77 -550 -225 -698 -142 -143 -300 -179 -850 -190 l-315 -7
2 -326 3 -326 3960 0 3959 0 53 145 c73 205 227 634 403 1130 84 234 197 551
252 705 143 402 245 688 278 780 15 44 72 202 125 350 113 315 120 334 114
338 -12 7 -676 227 -687 227 -7 0 -29 -35 -48 -77 -61 -134 -243 -492 -322
-632 -128 -229 -319 -523 -429 -660 -25 -31 -60 -74 -77 -96 -65 -83 -210
-237 -309 -329 -539 -502 -1203 -822 -1972 -950 -363 -61 -614 -76 -1285 -76
-722 0 -886 13 -990 80 -64 41 -95 76 -124 140 -40 88 -47 178 -53 622 -5 349
-3 418 8 422 8 2 120 33 249 69 227 62 247 70 595 227 198 89 398 179 445 200
138 61 431 191 485 215 28 13 97 44 155 70 58 26 188 91 290 145 102 54 226
119 275 145 50 26 171 90 270 143 99 52 250 132 335 177 85 45 198 105 250
132 52 28 133 70 180 95 452 239 566 297 810 410 41 20 197 93 345 163 791
374 711 340 980 407 597 147 733 178 935 216 118 23 224 43 235 45 30 6 897
-4 1160 -13z"/>
<path d="M15760 4495 c-163 -23 -262 -57 -395 -136 -106 -62 -183 -134 -241
-221 -173 -260 -142 -599 77 -819 69 -70 88 -83 230 -151 185 -90 179 -88 500
-182 315 -92 363 -109 464 -158 131 -64 185 -131 185 -229 0 -67 -29 -128 -82
-173 -116 -99 -381 -114 -597 -33 -167 62 -363 221 -479 390 -50 73 -122 205
-122 225 0 6 -4 13 -8 16 -9 5 -319 -67 -328 -77 -4 -3 5 -66 19 -139 14 -73
42 -221 61 -328 19 -107 48 -261 63 -342 l27 -148 131 0 131 0 38 85 c21 47
40 85 42 85 1 0 31 -16 65 -36 107 -63 221 -112 324 -140 134 -35 380 -45 525
-20 365 64 651 298 726 596 22 88 23 255 1 340 -48 183 -162 326 -345 430
-114 66 -230 109 -542 200 -373 110 -426 127 -493 162 -159 85 -212 230 -119
324 20 21 62 49 92 63 51 24 65 26 195 26 121 0 149 -3 210 -24 227 -77 393
-216 535 -446 24 -38 48 -76 54 -82 9 -10 46 -3 171 33 88 26 161 47 162 48 3
3 -13 67 -96 386 -27 107 -64 248 -80 314 -17 65 -32 120 -34 123 -4 3 -170 6
-244 3 -7 0 -26 -27 -43 -59 l-29 -59 -104 52 c-199 101 -414 135 -647 101z"/>
<path d="M3182 4293 l3 -168 105 -5 c116 -6 142 -15 167 -63 17 -30 18 -87 18
-834 0 -797 0 -802 -21 -830 -32 -43 -70 -57 -174 -63 l-95 -5 -3 -167 -2
-168 943 0 943 0 26 78 c14 42 74 212 133 377 141 395 160 453 153 461 -6 5
-233 89 -295 108 -17 5 -23 2 -28 -16 -19 -62 -83 -192 -133 -267 -160 -242
-357 -341 -681 -341 -84 0 -102 3 -125 21 l-26 20 0 790 c0 845 -1 829 50 874
19 17 39 21 131 23 l109 4 0 169 0 169 -600 0 -600 0 2 -167z"/>
<path d="M6326 4313 c-33 -82 -94 -240 -138 -353 -43 -113 -96 -248 -118 -300
-21 -52 -75 -187 -118 -300 -290 -752 -353 -908 -384 -953 -27 -37 -89 -68
-154 -74 l-54 -6 0 -168 0 -169 475 0 475 0 0 170 0 170 -57 0 c-124 1 -193
35 -193 94 1 17 27 103 59 191 l59 160 374 3 374 2 53 -161 c29 -89 53 -176
54 -195 2 -56 -39 -79 -161 -89 l-82 -8 0 -168 0 -169 585 0 585 0 0 169 0
169 -57 7 c-115 14 -147 41 -206 176 -38 87 -61 146 -273 694 -70 182 -144
375 -166 430 -34 87 -109 284 -273 710 l-42 110 -280 3 -279 2 -58 -147z m279
-635 c21 -62 73 -209 115 -325 43 -117 75 -215 72 -218 -8 -8 -470 -6 -475 3
-5 9 234 652 243 652 4 0 24 -51 45 -112z"/>
<path d="M8090 4291 l0 -169 109 -4 c105 -3 111 -5 139 -31 17 -16 33 -43 36
-60 4 -18 6 -385 6 -816 0 -601 -3 -790 -13 -809 -21 -46 -67 -63 -177 -69
l-100 -6 0 -168 0 -169 600 0 600 0 0 170 0 170 -82 1 c-109 1 -165 16 -192
52 -20 28 -21 39 -21 360 l0 332 448 3 447 2 0 -328 c0 -326 0 -329 -23 -363
-28 -42 -78 -57 -189 -58 l-78 -1 0 -170 0 -170 600 0 600 0 0 169 0 168 -102
5 c-118 7 -147 17 -174 61 -18 31 -19 62 -19 844 l0 813 33 32 c32 33 33 33
147 37 l115 3 0 169 0 169 -600 0 -600 0 0 -169 0 -168 115 -5 c110 -4 117 -6
143 -31 l27 -27 3 -295 3 -295 -451 0 -452 0 4 278 c3 258 4 279 23 312 27 46
67 59 183 59 l92 1 0 170 0 170 -600 0 -600 0 0 -169z"/>
<path d="M11050 4290 l0 -170 95 0 c61 0 108 -5 131 -15 66 -27 64 5 64 -884
0 -484 -4 -810 -10 -825 -17 -45 -49 -57 -169 -63 l-111 -6 0 -168 0 -169 600
0 600 0 0 170 0 170 -87 1 c-108 1 -159 15 -187 52 -21 28 -21 30 -21 835 0
761 1 809 18 840 26 48 69 62 182 62 l95 0 0 170 0 170 -600 0 -600 0 0 -170z"/>
<path d="M12520 4291 l0 -169 105 -4 c112 -4 146 -17 172 -69 10 -18 13 -207
13 -817 0 -885 3 -849 -67 -878 -20 -9 -79 -18 -130 -21 l-93 -6 0 -168 0
-169 600 0 600 0 0 169 0 168 -110 5 c-118 6 -145 16 -177 65 -16 24 -18 58
-18 333 l0 305 135 0 c124 0 138 -2 173 -24 62 -38 79 -86 85 -228 l5 -123
153 0 154 0 0 555 0 555 -154 0 -153 0 -5 -118 c-6 -131 -17 -165 -71 -206
-44 -34 -114 -47 -228 -44 l-94 3 0 325 0 325 280 -1 c234 0 291 -3 345 -18
186 -50 320 -168 429 -375 l51 -99 128 45 c70 25 138 48 152 52 14 4 26 14 28
23 2 8 -15 64 -36 124 -22 60 -74 208 -117 329 -43 121 -86 244 -97 273 l-19
52 -1020 0 -1019 0 0 -169z"/>
<path d="M17292 4293 l3 -168 105 -5 c116 -5 151 -19 173 -68 8 -19 13 -219
18 -712 7 -749 7 -749 68 -884 92 -202 289 -372 526 -453 224 -76 561 -71 780
12 220 82 376 219 471 410 94 190 104 292 104 1024 0 519 1 569 18 601 26 54
69 70 185 70 l97 0 0 170 0 170 -510 0 -510 0 0 -170 0 -170 95 0 c103 0 147
-12 180 -50 19 -21 20 -37 21 -574 1 -580 -5 -732 -31 -824 -40 -136 -137
-232 -286 -282 -54 -19 -81 -21 -174 -18 -99 3 -117 7 -177 35 -73 34 -147
102 -178 163 -11 21 -29 71 -42 111 -22 73 -22 85 -25 704 -3 599 -3 632 15
665 29 54 71 70 180 70 l92 0 0 170 0 170 -600 0 -600 0 2 -167z"/>
<path d="M20042 4293 l3 -168 105 -5 c114 -6 147 -19 168 -66 18 -37 17 -1620
-1 -1653 -25 -50 -57 -63 -171 -68 l-106 -6 0 -169 0 -169 788 4 c671 3 802 6
890 20 314 50 527 188 636 409 128 260 63 561 -159 734 -55 43 -114 72 -223
109 l-93 32 87 42 c144 69 236 153 299 272 165 314 1 672 -364 794 -158 52
-209 55 -1069 55 l-792 0 2 -167z m1337 -233 c63 -5 138 -17 167 -26 107 -33
173 -119 182 -233 12 -169 -94 -289 -284 -322 -38 -6 -166 -12 -284 -13 l-215
-1 -3 290 c-1 159 0 295 3 302 5 16 261 18 434 3z m103 -974 c181 -33 270
-120 294 -288 16 -115 -44 -258 -137 -326 -85 -63 -146 -75 -409 -80 -224 -4
-236 -3 -260 16 l-25 20 -3 336 -3 336 233 0 c152 -1 260 -5 310 -14z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
